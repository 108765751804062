import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'

export const showToast = (title, text, icon) => {
	const $toast = useToast()
	$toast.open({
		type: title,
		message: htmlToText(text),
		position: 'bottom-right',
		duration: 5000,
	})
}

export const htmlToText = (html) => {
	const div = document.createElement('div')
	div.innerHTML = html
	return div.textContent || div.innerText || ''
}

export const loading = () => {
	return useLoading({
		color: '#42B8FE',
	})
}

export function getFileSize(file_size) {
	let value = parseInt(file_size)
	if (value > 1048576) {
		return (value / 1048576).toFixed(2) + 'M'
	} else if (value > 1024) {
		return (value / 1024).toFixed(2) + 'K'
	}
	return value
}