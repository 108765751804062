import { defineStore } from 'pinia'

export const sidebarStore = defineStore('sidebar', {
	state: () => ({
		isSidebarOpen: true,
	}),
	actions: {
		toggleSidebar() {
			this.isSidebarOpen = !this.isSidebarOpen
		},
	},
})
