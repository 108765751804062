<template>
	<div
		v-if="show"
		class="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-[calc(100%)] max-h-full"
	>
		<div
			v-if="show"
			@click.self="show = false"
			class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
		></div>
		<div class="relative w-full max-w-2xl max-h-[85vh] overflow-y-auto rounded-lg">
			<!-- Modal content -->
			<div class="relative bg-white shadow dark:bg-gray-700">
				<!-- Modal header -->
				<div
					class="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600"
				>
					<h3 class="text-lg font-semibold text-gray-900 dark:text-white">
						Edit Profile
					</h3>
					<button
						type="button"
						class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
						@click="show = false"
					>
						<svg
							class="w-3 h-3"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 14 14"
						>
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
							/>
						</svg>
						<span class="sr-only">Close modal</span>
					</button>
				</div>

				<!-- Modal body -->
				<div class="isolate p-4 md:p-5">
					<div class="grid gap-4 mb-4 grid-cols-2">
						<div class="sm:col-span-2">
							<FileUploader
								v-if="!profile.image"
								:fileTypes="['image/*']"
								:validateFile="validateFile"
								@success="(file) => saveImage(file)"
							>
								<template v-slot="{ file, progress, uploading, openFileSelector }">
									<div class="mb-4">
										<Button @click="openFileSelector" :loading="uploading">
											{{
												uploading
													? `Uploading ${progress}%`
													: 'Upload a profile image'
											}}
										</Button>
									</div>
								</template>
							</FileUploader>
							<div v-else class="mb-4">
								<div
									class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>
									Profile Image
								</div>
								<div class="flex items-center">
									<div class="border rounded-md p-1 mr-2">
										<!-- <FileText class="h-5 w-5 stroke-1.5 text-gray-700" /> -->
										<img :src="profileImage" alt="" class="h-10 w-10" />
									</div>
									<div
										class="text-base flex flex-col text-gray-900 dark:text-white"
									>
										<span>
											{{ profile.image.file_name }}
										</span>
										<span class="text-sm text-gray-500 mt-1">
											{{ getFileSize(profile.image.file_size) }}
										</span>
									</div>
									<X
										@click="removeImage()"
										class="bg-gray-200 rounded-md cursor-pointer stroke-1.5 w-5 h-5 p-1 ml-4"
									/>
								</div>
							</div>
						</div>

						<div class="col-span-2">
							<label
								for="name"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Username</label
							>
							<input
								v-model="profile.username"
								type="text"
								name="name"
								id="name"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder="Type product name"
								required=""
							/>
						</div>
						<div class="col-span-2 sm:col-span-1">
							<label
								for="first-name"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>First Name</label
							>
							<input
								v-model="profile.first_name"
								type="text"
								name="first-name"
								id="first-name"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder=""
								required=""
							/>
						</div>
						<div class="col-span-2 sm:col-span-1">
							<label
								for="last-name"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Last Name</label
							>
							<input
								v-model="profile.last_name"
								type="text"
								name="last-name"
								id="last-name"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder=""
								required=""
							/>
						</div>

						<div class="col-span-2 sm:col-span-1">
							<label
								for="last-name"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Email</label
							>
							<input
								v-model="profile.email"
								type="email"
								name="email"
								id="email"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder=""
								required=""
							/>
						</div>
						<div class="col-span-2 sm:col-span-1">
							<label
								for="last-name"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Phone Number</label
							>
							<input
								v-model="profile.mobile_no"
								type="tel"
								name="phone-number"
								id="phone-number"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder=""
								required=""
							/>
						</div>
						<div class="col-span-2">
							<label
								for="password"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Password</label
							>
							<input
								v-model="profile.password"
								type="password"
								name="password"
								id="password"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder=""
								required=""
							/>
						</div>
					</div>
					<div class="mt-10">
						<button
							class="align-middle select-none rounded-lg font-medium text-cente transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-2 px-6 bg-transparent text-[#FFFFFFB2] shadow-md shadow-trabg-transparent/10 hover:shadow-lg hover:shadow-trabg-transparent/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none capitalize w-full"
							type="button"
							data-ripple-light="true"
							style="
								background: linear-gradient(
									94.86deg,
									rgb(181, 0, 204) 3.32%,
									rgb(0, 31, 205) 103.12%
								);
							"
							@click="saveProfile"
						>
							Update
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { createResource, FileUploader } from 'frappe-ui'
import { computed, reactive, ref, watch } from 'vue'
import { loading, showToast, getFileSize } from '@/utils'
import { FileText, X } from 'lucide-vue-next'
const loader = ref()
const showSpinner = ref(false)
const $loading = loading()

const emit = defineEmits(['update:showModal'])
const server_path = import.meta.env.VITE_SERVER_PATH

// const show = defineModel()
const props = defineProps({
	showModal: Boolean,
	user: {
		type: Object,
		required: true,
	},
})

const show = ref(false)

watch(
	() => props.showModal,
	(newVal) => {
		show.value = newVal
	},
)

watch(show, (newVal) => {
	emit('update:showModal', newVal)
})

const profile = reactive({
	first_name: '',
	last_name: '',
	username: '',
	email: '',
	mobile_no: '',
	password: '',
	image: '',
})

const profileImage = computed(() => {
	return `${profile.image.file_url}` //${server_path}/
})

const validateFile = (file) => {
	let extension = file.name.split('.').pop().toLowerCase()
	if (!['jpg', 'jpeg', 'png'].includes(extension)) {
		return 'Only image file is allowed.'
	}
}

const saveImage = (file) => {
	profile.image = file
}

const removeImage = () => {
	profile.image = null
}

const imageResource = createResource({
	url: 'rebuid.rebuid.api.get_file_info',
	makeParams(values) {
		return {
			file_url: values.image,
		}
	},
	auto: false,
	onSuccess(data) {
		profile.image = data
	},
})

const updateProfile = createResource({
	url: 'frappe.client.set_value',
	makeParams(values) {
		return {
			doctype: 'User',
			name: props.user.name,
			fieldname: {
				user_image: profile?.image?.file_url,
				...profile,
			},
		}
	},
})

const saveProfile = () => {
	loader.value = $loading.show() // Show loader before submission
	showSpinner.value = true
	updateProfile.submit(
		{},
		{
			onSuccess(data) {
				loader.value?.hide() // Hide loader on success
				showSpinner.value = false
				if (data.status === 'error') {
					showToast('error', data.message, 'x')
					return
				}

				show.value = false
				emit('profile-updated')
				showToast('success', 'profile updated successfully', 'x')
			},
			onError(err) {
				loader.value?.hide() // Hide loader on error
				showSpinner.value = false
				showToast('error', err.messages?.[0] || err, 'x')
				show.value = false
			},
		},
	)
}

watch(
	() => props.user,
	(newVal) => {
		if (newVal) {
			profile.first_name = newVal.first_name
			profile.last_name = newVal.last_name
			profile.username = newVal.username
			profile.mobile_no = newVal.mobile_no
			profile.email = newVal.email
			if (newVal.user_image) imageResource.submit({ image: newVal.user_image })
		}
	},
)
</script>
