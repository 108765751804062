<template>
	<div class="">
		<div class="flex h-screen overflow-y-hidden bg-[#f5f6fc] dark:bg-[#111827] scrollbar">
			<DashboardSidebar />
			<div
				id="main-container"
				class="flex flex-col flex-1 h-full overflow-auto relative body-scrollBar"
			>
				<DashboardNavbar />
				<router-view />
			</div>
		</div>
		<div>
			<DashboardFooter />
		</div>
	</div>
</template>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

import DashboardSidebar from '../widgets/DashboardSidebar.vue'
import DashboardNavbar from '../widgets/DashboardNavbar.vue'
import DashboardFooter from '../widgets/DashboardFooter.vue'


// initialize components based on data attribute selectors
onMounted(() => {
	initFlowbite()
})
</script>