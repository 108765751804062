<template>
	<div>
		<button
			id="dropdownAvatarNameButton"
			data-dropdown-toggle="dropdownAvatarName"
			class="flex items-center text-gray-900 relative rounded-lg py-2 px-3 hover:bg-gray-100 hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 dark:text-white dark:hover:bg-gray-700"
			data-ripple-dark="true"
			type="button"
		>
			<span class="sr-only">Open user menu</span>
			<img
				class="w-10 h-10 md:mr-2 rounded-full"
				:src="profileImage"
				alt="user photo"
			/>
			<div class="text-left hidden md:block">
				<h1 class="text-[#181F3A] text-base font-semibold dark:text-white">
					{{ user?.full_name }}
				</h1>
				<p class="text-[#727B9D] text-sm">{{ user?.stripe_customer_detail?.plan_detail?.product_name }}</p>
			</div>
			<svg
				class="w-2.5 h-2.5 ml-5 text-[#727B9D] hidden md:block"
				aria-hidden="true"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 10 6"
			>
				<path
					stroke="currentColor"
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="m1 1 4 4 4-4"
				/>
			</svg>
		</button>

		<!-- Dropdown menu -->
		<div
			id="dropdownAvatarName"
			class="hidden z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
			aria-labelledby="dropdownAvatarNameButton"
		>
			<div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
				<div class="font-medium">
					{{ user?.full_name }}
				</div>
				<div class="truncate">{{ user?.email }}</div>
			</div>
			<ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
				<li>
					<router-link
						:to="{ name: 'home' }"
						class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
					>
						Home
					</router-link>
				</li>
				<li>
					<p @click="openEditProfileModal"
						class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
					>
						Edit Profile
					</p>
				</li>
			</ul>
			<div class="py-2">
				<router-link
					to="#"
					class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
					@click="logout"
				>
					Logout
				</router-link>
			</div>
		</div>

		<!-- Edit profile modal -->
		<EditProfileModal v-model:showModal="showEditProfileModal" :user="user" @profile-updated="handleProfileUpdated" />
	</div>
</template>

<script setup>
import { computed, ref } from 'vue';
import EditProfileModal from '../Modals/EditProfileModal.vue';
import { session } from '../../data/session';

const emit = defineEmits(['profile-updated']);

const handleProfileUpdated = () => {
	emit('profile-updated');
};

const server_path = import.meta.env.VITE_SERVER_PATH

const props = defineProps({
	user: Object,
})

const profileImage = computed(() => {
	return props?.user?.user_image ? props.user.user_image : `https://ui-avatars.com/api/?name=${props?.user?.full_name}`
})

const showEditProfileModal = ref(false)

const openEditProfileModal = () => {
	showEditProfileModal.value = true
}

const logout = () => {
	session.logout.submit()
}
</script>
